import React from 'react'
import Layout from '../components/common/Layout'
import { Seo } from '../components/meta/Seo'

const contact = () => {
  return (
    <Layout>
      <Seo title="Contact" />
      <div className="container">Contact</div>
    </Layout>
  )
}

export default contact
